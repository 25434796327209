import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Image from "next/legacy/image";
import Link from "next/link";
import Img404 from "../public/img/404.png";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}

export default function Custom404() {
  return (
    <>
      <style>{`body { background-color: black; } `}</style>
      <div className="bg-black pb-[10px]">
        <div className="container mx-auto text-white pt-[275px] text-center">
          <Image src={Img404} width={368} height={200} />
          <div className="text-[26px] mt-[40px] mb-[16px] text-[#ff2800]">
            Whoops!
          </div>
          <div className="mb-[110px]">
            {`We couldn't find the site you were looking for.`}
          </div>
          <Link
            href="/"
            passHref
            className="btn-unstyled mx-auto w-[216px] h-[46px] rounded-full text-[16px] bg-white text-black hover:bg-[#E3E5E7] flex justify-center items-center"
          >
            BACK TO HOME
          </Link>
        </div>
      </div>
    </>
  );
}
